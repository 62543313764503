import { render, staticRenderFns } from "./MaintenancePlanDetailTable.vue?vue&type=template&id=6cf8cdbb&scoped=true&"
import script from "./MaintenancePlanDetailTable.vue?vue&type=script&lang=js&"
export * from "./MaintenancePlanDetailTable.vue?vue&type=script&lang=js&"
import style0 from "./MaintenancePlanDetailTable.vue?vue&type=style&index=0&id=6cf8cdbb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf8cdbb",
  null
  
)

export default component.exports