<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    dataTable: { type: Array },
    page: { type: Number },
    perPage: { type: Number },
    searchText: {
      type: String,
    },
    roundDate: {
      type: String,
    },
    otherColumn: [],
  },
  beforeMount() {
    let columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        maxWidth: 110,
        suppressSizeToFit: true,

        getQuickFilterText: (params) => {
          return "";
        },
      },
      {
        headerName: this.$t("maintenance_plan.maintenance_code"),
        field: "maintenance_code",
        sortable: true,
        suppressSizeToFit: true,
        minWidth: 300,
      },
      {
        headerName: this.$t("home.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.as_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.as_dt"),
        field: "description",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.cat"),
        field: "category",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.sub_cat"),
        field: "sub_category",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.build"),
        field: "location_building",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.fl"),
        field: "location_floor",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.department"),
        field: "location_department",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.zone"),
        field: "location_zone",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.note"),
        field: "note",
        sortable: true,
        minWidth: 150,
      },
      {
        headerName: "QR Code",
        field: "id_qrcode",
        sortable: true,
        suppressSizeToFit: true,
        cellStyle: (params) => {
          if (params.value === this.$t("home.not_reg")) {
            return { color: "#888888" };
          }
          return null;
        },
      },
    ];

    this.columnDefs = columnDefs;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loopHeaderFreeColumn();
    console.log(this.dataTable);
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),
    rowData() {
      return this.dataTable.map((asset, index) => {
        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          maintenance_code: this.createMaintenanceCode(asset.asset), //MA(Plan ID 4 digits)-YYMMDD(id_fixed_asset)
          id_fixed_asset: asset.asset.id_fixed_asset,
          name: asset.asset.name ? asset.asset.name : "-",
          description: asset.asset.description ? asset.asset.description : "-",
          category: asset.asset.categories ? asset.asset.categories.name : "-",
          sub_category: asset.asset.sub_category
            ? asset.asset.sub_category.name
            : "-",
          location_building: asset.asset.location_buildings
            ? asset.asset.location_buildings.name
            : "-",
          location_floor: asset.asset.location_floors
            ? asset.asset.location_floors.name
            : "-",
          location_department: asset.asset.location_departments
            ? asset.asset.location_departments.name
            : "-",
          location_zone: asset.asset.location_zones
            ? asset.asset.location_zones.name
            : "-",
          note: asset.asset.note ? asset.asset.note : "-",
          id_qrcode: asset?.asset?.qrcode?.id_qrcode
            ? asset?.asset?.qrcode?.id_qrcode
            : this.$t("home.not_reg"),
          asset_sector: asset.asset.sector?.name || "-",
          asset_branch: asset.asset.branch?.name || "-",
          insurance_start: asset.asset.custom_start_time
            ? this.momentDay(asset.asset.custom_start_time)
            : "-",
          insurance_end: asset.asset.custom_end_time
            ? this.momentDay(asset.asset.custom_end_time)
            : "-",
          vendor: asset.asset.vendor?.name || "-",
        };
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  methods: {
    createMaintenanceCode(asset) {
      let planId = this.$route.params.id;
      while ((planId + "").length < 4) {
        planId = "0" + planId;
      }
      return (
        "MA" +
        planId +
        "-" +
        moment(this.roundDate).format("YYMMDD") +
        asset.id_fixed_asset
      );
    },
    loopHeaderFreeColumn() {
      let columnDefs = this.columnDefs;
      if (!this.isRoleLower) {
        const customColumnJGT = [
          {
            position: 7,
            value: {
              headerName: this.$t("asset.location"),
              field: "asset_sector",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 8,
            value: {
              headerName: this.$t("asset.branch"),
              field: "asset_branch",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 20,
            value: {
              headerName: this.$t("asset.insurance_start"),
              field: "insurance_start",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 21,
            value: {
              headerName: this.$t("asset.insurance_end"),
              field: "insurance_end",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
          {
            position: 22,
            value: {
              headerName: this.$t("asset.vendor"),
              field: "vendor",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
        ];
        let fix_column = [
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.acc_depreciation"),
            field: "acc_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("asset.net_book"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
        ];
        // columnDefs = columnDefs.concat(fix_column);
        if (this.permissionStatus.company.custom_id === 2) {
          for (const item of customColumnJGT) {
            columnDefs.splice(item.position, 0, item.value);
          }
        }
      }
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      this.$emit("selected-assets", selectedData);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
